.timeline-background {
//     background-color: $blue;
    margin-top: -31px;
    height: 100%;
    padding-top: 40px;

    @include breakpoint(small only) {
        margin-top: -10px;
    }
}

.timeline__date {
    font-size: 0.8rem;
    margin-bottom: -10px;
    color: $white;
}

.timeline__year {
    font-weight: 600;
    font-size: 1.8rem;
    margin: 0;
}

.timeline__title {
    color: $dark-gray;
    margin-top: 0.5rem;
    font-size: 1.6rem;
}

.timeline__body {
    font-size: 0.8rem;
    color: $white;
    overflow-y: scroll;
    .timeline-link {
        color: $baby-blue;
    }
    .timeline-link:hover {
        color: $baby-blue;
    }
    a {
        color: $pink;
    }
    a:hover {
        color: $pink;
    }
}

.tl-caption {
	color: $white;
}

.timeline__link {
    color: $white;
    font-size: 0.8rem;

    &:hover {
        color: $pink;
    }
    &:visited {
        color: $white;
    }
}

.orbit-previous,
.orbit-next {
    top: 26px !important;
    cursor: pointer !important;
}

.show-for-sr,
.orbit-next-icon,
.orbit-previous-icon {
    cursor: pointer !important;
}

.orbit-previous {
    left: auto !important;
    right: 60px !important;
}

.orbit-previous:hover,
.orbit-next:hover,
.orbit-previous:active,
.orbit-next:active,
.orbit-previous:focus,
.orbit-next:focus {
    background-color: transparent !important;
}

.data-slide-pink {
    stroke: $pink;
}

.data-slide-blue {
    stroke: $blue;
}

.data-slide-gray {
    stroke: $white;
}

.active-circle {
    fill: $pink;
    stroke: $pink;
    stroke-width: 3px;
    animation: circlePulse 2s ease-in-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

.active-circle-mobile {
    fill: $pink;
    stroke: $pink;
    stroke-width: 3px;
    animation: circlePulse 2s ease-in-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

.spiral-wrapper {
    // overflow: hidden;
    max-height: 500px;

}

#spiral,
#spiral-mobile {
    animation: spiralPulse 4s ease-in-out; // animation: spiralRotate 6s ease-in-out;
    animation-iteration-count: infinite;

    &:hover {
        animation: none;
    }
}

line {
    stroke-width: 2px;
}

.timeline-body {
    background-color: $blue;
    background-image: url('/images/shattered-pattern.gif')
}

.footer--timeline {
    background-color: $blue;
    color: white;

    .footer__links {
        a {
            color: white !important;
        }
    }
}

@keyframes spiralPulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.96);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes circlePulse {
    0% {
        stroke-width: 3px;
    }

    100% {
        stroke-width: 6px;
    }
}
