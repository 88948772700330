#calendar-container {
  @include breakpoint(large) {
    margin-top: -50px;
  }

  @include breakpoint(medium down) {
    margin-bottom: 1rem;
  }
}

.calendar {
  text-align: center;
  background-color: $white;
  border: $border;
}

.calendar-nav {
  border-bottom: $border;
  height: 3rem;
  line-height: 3rem;
}

.calendar-nav__button {
  height: 3rem;
  padding: 0 16px;
  color: $dark-gray;
  cursor: pointer;
}

.calendar-nav__button--prev {
  float: left;
  border-right: $border;
}

#calendar-toggle {
  float: right;
  border-left: $border;
}

.calendar-nav__button--next {
  float: right;
  border-left: $border;
}

.calendar-nav__month {
  color: $blue;
}

.calendar-main {
  padding: 1rem;

  @include breakpoint(medium down) {
    display: none;
  }
}

.calendar-main__days {
  text-transform: uppercase;
  font-size: 0.8rem;
}

.calendar-main__date-wrapper {
  color: $blue;
  font-size: 1rem;
}

.calendar-main__date {
  height: 40px;
  line-height: 40px;
}

.calendar-main__date-event-link {
  color: $blue;
  border-radius: 50%;
  padding: 0px 3px 1px 3px;
  animation: border 1s ease-in;
  border: 1px solid $pink;

  &:hover {
    color: $white;
    background-color: $pink;
  }
}

@keyframes border {
  0% {
    border: 1px solid rgba(255, 0, 102, 0);
  }

  100% {
    border: 1px solid rgba(255, 0, 102, 1);
  }
}

.current-date {
  color: $pink;
}
