.post-preview__category {
  color: $blue;
  border-radius: 2px;
  border: 1px solid $pink;
  padding: 4px 10px;
  margin-right: 4px;
  line-height: 34px;
  font-family: 'Roboto Condensed', sans-serif;

  &:hover {
    background: $pink;
    color: $white;
  }
}

.post-preview__category--featured {
  color: $white;
  background: $pink;
}

$food-and-agriculture : $pink;
$cdn-ai-news          : #06e56f;
$fintech              : #fe8903;
$education            : #21c3ee;
$healthtech           : #ffc300;
$general-ai-news      : #d6100d;
$ai-explainer         : #29cac1;

$industries: (food-and-agriculture : $food-and-agriculture,
              cdn-ai-news          : $cdn-ai-news,
              fintech              : $fintech,
              education            : $education,
              healthtech           : $healthtech,
              general-ai-news      : $general-ai-news,
              ai-explainer         : $ai-explainer);

@mixin category--industry($color) {
  border: 1px solid $color;

  &:hover {
    background: $color;
  }
}

@mixin category--featured-industry($color) {
  color: $white;
  background: $color;
}

@each $industry, $industryColor in $industries {
  .post-preview__category--#{$industry} {
    @include category--industry($industryColor);
  }

  .post-preview__category--featured-#{$industry} {
    @include category--featured-industry($industryColor);
  }
}
