
ul {
  list-style-type: none !important;
  &.alpha-index-bar {
    margin: auto;
    .current {
      font-weight: 700;
    }
  }
  &.hideMenu {
    display: flex;
  }
//  &.alpha-index-list {
//    display: none;
//  }
}

sup {
  display: none;
  
}
.directory {
  border: 1px solid $light-gray;
  border-top: none;
  padding-top: 1%;
  padding-bottom: 1%;
  max-width: 72.5rem!important;
  .post-preview {
    margin-bottom: 20px;
  }
  .main-header {
    margin-bottom: 0;
    font-family: 'Montserrat Light', sans-serif;
    font-weight: 100;
  }

  @include breakpoint(small only) {
    margin-left: 4%!important;
    margin-right: 4%!important;
  }
  .post-preview__body {
    width: 100%;
    margin-bottom: 10px;
  }
  .post-preview__date {
    font-weight: bold;
  }

  .post-preview__website {
    text-decoration: underline;
    color: $dark-gray;
    font-size: 12px;
    font-weight: bold;
    margin-top: 20px;
  }

  .post-preview__funds {
    color: $dark-gray;
    font-size: 12px;
    font-weight: bold;
  }
  .post-dot {
    position: relative;
    margin-left: 7px;
    margin-right: 10px;
    i {
      position: absolute;
      top: 8px;
      left: -1.5px;
      font-size: 5px;
    }
  }
}

.directory__sub-title {
  margin-top: 50px;
  margin-bottom: 20px;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  border-bottom: 1px solid $light-gray;
}

.directory__title--accordion,
.directory__title--accordian-startup {
  display: flex;
  margin-right: auto;
  max-width: 72.5rem;
  margin-left: auto;
  margin-top: 20px;
  width: 100%;
  cursor: pointer;
  padding: 12px 1.2rem;
  border: 1px solid $light-gray;
  background: white;
  font-size: 20px;
  margin-bottom: 0;
  color: #251159;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 800;
  font-family: 'Montserrat', sans-serif;
  i {
    color: gray;
    margin-left: auto;
    margin-top: 6px;
  }
  .directory__heading {
    color: $blue;
    font-weight: bold;
  }
  .directory-cta__icon-minus {
    display: none;
  }
  @include breakpoint(small only) {
    margin-right: 4%;
    margin-left: 4%;
    width: auto;
  }
}

.directory__title {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  .directory__heading {
    color: $blue;
    font-weight: bold;
  }
  .directory-cta {
    display: flex;
    text-align: right;
    padding-right: 1.2rem!important;
    .cta-button {
      border: 1px solid $light-gray;
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 15px;
      padding-bottom: 15px;
      font-weight: bold;
      text-transform: uppercase;
      color: #251159;
      font-size: 12px;
      cursor: pointer;
      .directory-cta__icon {
        color: $pink;
        padding-right: 5px;
      }
    }
  }
  .directory__title--logo {
    position: relative;
    width: 250px;
    height: 75px;
    text-align: center; //    border: 1px solid $light-gray;
    //    background: white;
    margin-bottom: 50px;
    p {
      position: absolute;
      font-size: 12px;
      left: 7px;
      top: 7px;
    }
    img {
      width: 70%;
      margin-top: 25px;
    }
  }
  @include breakpoint(small only) {
    text-align: center;
    h3 {
      width: 100%;
    }
    .directory-cta {
      text-align: center;
      padding-right: 0!important;
    }
    .directory__title--logo {
      margin: 0 auto;
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }
}
