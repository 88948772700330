.post-text {
  font-size: 0.8rem;
}

.post-main {
  margin-top: 2rem;
}

.img-wrapper__caption {
  font-size: 0.8rem;
  font-weight: 600;
}

.img-wrapper__img {
  width: 100%;
}

.post-preview--post {
  margin-bottom: 1rem;
}

.post--bottom {
  border-top: $border;
  border-bottom: $border;
  margin-top: 2rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.post--bottom__source {
  display: inline-block;

  p {
    font-size: 0.7rem;
    margin: 0;
  }

  h6 {
    font-size: 0.9rem;
    color: $blue;
    font-weight: 500;
    margin: 0;
  }
}



.post-preview__title {
  i {
    color: $white;
  }
  .article-link {
    color: $white;
    display: inline-block;
    &:hover {
      color: #ff0066;
    }
  }
}

.post-preview__social__icon--post {
  margin-left: 14px;
}

.post--bottom__share {
  padding-top: 8px;
  display: inline-block;
  float: right;
}

.post--bottom__share-link {
  color: $dark-gray;

  &:hover {
    color: $pink;
  }
}

.related-heading {
  color: $blue;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 800;
  margin-top: 2rem;
  margin-bottom: -1rem;
}
