.about-img {
  margin-bottom: 2rem;
}

.main-header {
  color: $blue;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 1.5rem;
  font-family: 'Montserrat', sans-serif;

  @include breakpoint(small only) {
    margin-bottom: 0.5rem;
  }
}

.about {
  .about-forms {
    display:flex;
    border:1px solid $light-gray;
    align-items: center;
    margin-bottom:10px;
    p {
      margin-bottom:0!important;
      color:$blue;
      text-transform: uppercase;
      font-weight:bold;
      font-size:12px;
      margin-left:10px;
      margin-right:10px;
    }
    .about-forms__icon {
      border-right:1px solid $light-gray;
      width:50px;
      height:39px;
      text-align: center;
      i {
        color:$pink;
        margin-top:11px;
      }
    }
    .about-forms__a {
      color:$blue;
      font-size:12px;
      font-weight:bold;
      margin-left:10px;
      font-family: 'Roboto', sans-serif;
      text-transform: uppercase;
    }
    .submit-icon {
      color:$dark-gray;
      margin-left:auto;
      margin-right:10px;
    }
    .slack-sub-about {
      display:flex;
      border:none;
      width:89%;
      input {
        margin:0;
        padding:0;
        border:none;
        background:transparent;
        margin-left:10px;
      }
      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color:$blue;
        font-size:12px;
        font-weight:bold;
        font-family: 'Roboto', sans-serif;
        text-transform: uppercase;
      }
      .about-slack {
        display:none;
      }
      .about__submit {
        margin-right:11px;
        color:$dark-gray;
        cursor: pointer;
      }
      @include breakpoint(small only) {
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          font-size:10px;
        }
      }
    }
    .sub-about {
      display:flex;
      border:none;
      width:89%;
      input {
        margin:0;
        padding:0;
        border:none;
        background:transparent;
        margin-left:10px;
      }
      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color:$blue;
        font-size:12px;
        font-weight:bold;
        font-family: 'Roboto', sans-serif;
        text-transform: uppercase;
      }
      .about-sub {
        display:none;
      }
      .about__submit {
        margin-right:11px;
        color:$dark-gray;
        cursor: pointer;
      }
      @include breakpoint(small only) {
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          font-size:10px;
        }
      }
    }

  }
  @include breakpoint(small only) {
    text-align: center;
    .about-forms {
      p {
        font-size:10px;
      }
      .about-forms__a {
        font-size:10px;
      }
    }
  }
}

.event-select {
  option {
    color: $blue;
  }
}

.logo-title {
  width: 45%;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
  font-weight:bold;
  color:$blue;
  span {
    color:$pink;
  }
  @include breakpoint(small only) {
    width:70%;
  }
}

.sponsor-container {
  margin-top: 20px;
  .partner-logos__item {
    height:100px;
    margin-top:20px;
    .logo-box {
      position:relative;
      margin:0 auto;
      width:80%;
      height:100%;
/*
      background:white;
      border:1px solid $light-gray;
*/
      img {
        position: absolute;
        top: 50%;
        left:50%;
        transform: translate(-50%, -50%);
        //Only for borealis logo
        &.tall-image {
          height: 100%;
          padding-top: 7px;
          padding-bottom: 7px;
        }
        &.small-image {
          padding: 55px;
        }
      }
    }
    @include breakpoint(small only) {
      height: 70px;
      margin-bottom: 20px;
      img {
        width:75%;
      }
    }
  }
}

.main-header--internal {
  margin-bottom: 0;

  @include breakpoint(small only) {
    margin-top: 0.5rem;
    margin-bottom: -1rem;
  }
}
