.partner-logos {
  padding-bottom:20px;
  display:flex;
  justify-content: space-around;
  max-width: 1180px;
  margin: 0 auto;
  // Borealis logo
  img {
    padding: 15px;
    @include breakpoint(small only) {
      padding: 0;
    }
//    &.cifar {
//      padding-right: 15px;
//      padding-left: 15px;
//    }
//    &.tall-image {
//      padding: 15px;
//    }
  }
  @include breakpoint(small only) {
    flex-wrap:wrap;
    justify-content: space-around;
  }
  .partner-logos__item {
    text-align:center;
    flex-basis: 14.25%;
    margin: auto 0; 
    @include breakpoint(small only) {
      flex-basis:25%;
      margin-bottom:20px;
      img {
        width:50%;
      }
    }
  }
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  border-top: $border;
  .news-sub {
    display:none;
  }
  @include breakpoint(small only) {
    height: 50px;
  }

  div:first-child {
    padding: 0;
  }

  div:nth-child(2) {
    text-align: center;
  }
  .nav__input-wrapper {
    margin-top:20px;
  }
  .footer__links {
    list-style: none;
    color: $dark-gray;
    font-size: 0.8rem;
    display: flex;
    align-items: stretch; /* Default */
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding-left: 1rem;
    padding-right: 1rem;

    li {
      display: block;
      flex: 0 1 auto; /* Default */
      display: inline-block;
    }

    a {
      color: $black;
      font-weight: 500;
      padding-left: 0 !important;
      padding-right: 0 !important;

      &:hover {
        color: $pink;
      }
    }
  }

  .footer__copyright-wrapper {
    text-align: right;
    line-height: 50px;
  }

  .footer__copyright {
    font-size: 11px;
    font-weight: 500;
    line-height: 0.8rem;
    display: inline-block;
  }

  .footer__logo-wrapper {
    width: 75px;
    display: inline-block;
  }
}
