.main-header--search {
  font-size: 1.2rem;
  margin-bottom: 0;
}

.main-header--search-span {
  font-style: italic;
  font-family: "Roboto Condensed", sans-serif;
}

.search-result__center {
  margin: 0 auto;
}
