.nav {
  margin-bottom: 2rem;
  background-color: $white;
  -webkit-box-shadow: 0 10px 6px -6px $light-gray;
	-moz-box-shadow:    0 10px 6px -6px $light-gray;
	box-shadow:         0 10px 6px -6px $light-gray;

  @include breakpoint(small only) {
    margin-bottom: 0.7rem;
  }
}

.nav-column {
  height: 40px;
}

.slack-sub {
  height:100%;
  display:flex;
  .slack-success {
    display:none;
  }
}

.nav__top-row {
  height: 80px;

  @include breakpoint(small only) {
    height: 50px;
  }
}

.hidden-input {
  visibility: hidden;
}

.logo {
  height: 40px;
}

.logo-text {
  font-size: 0.8rem;
  position:absolute;
  @include breakpoint(small only) {
    display: none;
  }
}


.logo-mobile {
  width: 32px;
}

.nav__input-wrapper {
  display: inline-block;
  float: right;
  height: 40px;
}

.nav__input {
  margin-bottom: 0!important;
  height: 100% !important;
  font-size: 14px !important;

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $dark-gray;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    color: $dark-gray;
  }

  &:-ms-input-placeholder { /* IE 10+ */
    color: $dark-gray;
  }

  &:-moz-placeholder { /* Firefox 18- */
    color: $dark-gray;
  }
}

.nav__input-wrapper {
  position: relative;
}

.nav__input--subscribe {
  width: 14rem !important;
  padding-left: 30px !important;
}

.nav__input--submit {
  margin-right:10px;
  font-size: 0.8rem;
  border: 1px solid $light-gray;
  border-left:none;
  padding: 0 10px;
  color:#7d7d9d;
  background:white;
  cursor:pointer;
}

.input-icon {
  position: absolute;
  z-index: 1;
}

.input-icon--envelope {
  top: 12px;
  left: 10px;
}

.input-icon--search {
  right: 10px;
  top: 12px;
  cursor: pointer;
}

#search-form {
  height: 100%;
}

.nav__input--search {
  width: 17rem !important;
  margin-left: -0.3rem !important;
  padding-left: 15px !important;

  @include breakpoint(medium only) {
    width: 12em !important;
  }
}

.nav__bottom-row {
  height: 40px;
  border-top: $border;
}

.nav-menu {
  margin-left: 0!important;
}

.nav-menu__link {
  color: $blue;
  font-size: 0.86rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: $pink;
  }
}

@include breakpoint (medium) {
  .padding-right {
    padding-right: 0;
  }
}

.nav-menu__link--left {
  height: 40px;
  line-height: 18px !important;
  border-right: $border;
  padding-right: 1.5rem;
  font-family: "Roboto Condensed", sans-serif;
  padding-left: 1.5rem;

  @include breakpoint(medium) {
    border-left: $border;
  }

  @include breakpoint(small only) {
    padding-left: 0 !important;
  }

  i {
    line-height: 18px !important;
    margin-right: 0.25rem;
  }

  // &::after {
  //   display: none !important;
  // }
}

#menu {
  width: 225px !important;
}

.nav-menu--horizontal {
  list-style: none;
  color: $dark-gray;
  font-size: 0.8rem;
  display: flex;
  align-items: stretch; /* Default */
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}

.nav-menu__link--horizontal {
  display: block;
  flex: 0 1 auto; /* Default */
  display: inline-block;
}

#browse-categories-wrapper, #more-categories-wrapper {
  position: relative;
  text-align: right;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}


#menu-wrapper {
  position: relative;
  text-align: left;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

#browse-categories-menu, #more-categories-menu, #browse-categories-menu-fixed {
  display: inline-block;
  position: absolute;
  width: 180px;
  list-style: none;
  background-color: $white;
  border: $border;
  display: none;
  z-index: 99;
}

#more-submit-menu {
  display: inline-block;
  position: absolute;
  width: 180px;
  list-style: none;
  background-color: $white;
  border: $border;
  display: none;
  z-index: 99;
  right: -1px;
  top: 38px;
}

#main-menu, #main-menu-fixed {
  display: inline-block;
  position: absolute;
  left: -1px;
  top: 39px;
  width: 180px;
  list-style: none;
  background-color: $white;
  border: $border;
  display: none;
  z-index: 99;
}

#main-menu-fixed, #browse-categories-menu-fixed {
  position: fixed;
}

.nav-menu--vertical-right {
  list-style: none;

  .nav-menu-li {
    display: inline-block;
    float: right;
    width: 100%;
    text-align: right;
    list-style: none;
    padding-right: 1rem;
    line-height: 40px;
    border-bottom: $border;
  }

  @include breakpoint(medium) {
    border-right: $border;
  }

  @include breakpoint(small only) {
    padding-right: 0 !important;
  }
}

.nav-menu--vertical-left {
  list-style: none;

  .nav-menu-li {
    display: inline-block;
    float: left;
    width: 100%;
    text-align: left;
    list-style: none;
    padding-left: 1rem;
    line-height: 40px;
    border-bottom: $border;
    text-decoration: none;
  }

  @include breakpoint(medium) {
    border-left: $border;
  }

  @include breakpoint(small only) {
    padding-left: 0 !important;
  }
}
.menu-arrow {
  color: $dark-gray;
  font-size: 10px;
  padding-left: 4px;
}

.nav-menu__link--right {
  border-left: $border;
  height: 40px;
  line-height: 40px !important;
  display: inline-block;
  padding-left: 1rem;

  @include breakpoint(medium down) {
    line-height: 43px !important;
  }

  &::after {
    border-color: $dark-gray transparent transparent !important;
  }
}

.nav-menu__link--left {
  border-right: $border;
  height: 40px;
  line-height: 40px !important;
  display: inline-block;
  padding-right: 1rem;

  @include breakpoint(medium down) {
    line-height: 43px !important;
  }

  &::after {
    border-color: $dark-gray transparent transparent !important;
  }
}

.fixed-menu {
  // overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 98;
  display: none;

  .nav__bottom-row {
    background: $white;
    border-bottom: $border;
  }
  
  .fix-logo-mark {
    width: 27px;
    margin-right: 10px;
  }

  #main-menu, #browse-categories-menu {
    position: fixed;
    z-index: 100;
  }
}
