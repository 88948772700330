.social {
  margin: auto;
  display: inline-block;
}

.social--nav {
  float: right;
  margin-right: 2rem;

  @include breakpoint(medium down) {
    margin-right: 15px;
  }
}

.social__icon-wrapper {
  text-align: center;
  display: inline-block;
  margin: 0 2.5px !important;
  border: $border;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  line-height: 40px;
  .social__icon--footer {
    margin-right: 0;
    margin-top: 0.2rem;
  }
}

.social__icon {
  color: $dark-gray;
  line-height: 40px;
  font-size: 1rem;
}

@include breakpoint( small only ) {

  .social__icon-wrapper {
    height: 28px;
    width: 28px;
    line-height: 28px;
  }

  .social--nav {
    margin-top: 6px;
  }
}
