.post-preview {

  @include breakpoint(small only) {
    border-bottom: $border;
    padding-bottom: 1rem;
  }
}

.post-preview__category, .post-preview__date, .post-preview__source, .post-preview__subtitle, .post-preview__social {
  font-size: 0.8rem;
  font-weight: 500;
}

.post-preview__social {
  margin-right: 14px;

  a {
    color: $dark-gray;

    &:hover {
      color: $pink;
    }
  }
}

.post-preview__social--featured {
  a {
    color: $white;
  }
}

.post-preview__title {
  color: $blue;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.2rem;
  display: block;
  margin: 2px auto;

  &:hover {
    color: $blue;
    text-decoration: underline;
  }
}

.post-preview__subtitle {
  margin: 4px auto;
}

.hero {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @include breakpoint( medium down ) {
    min-height: 450px;
  }

  &::before {
    display: block;
    position: absolute;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    bottom: 0px;
    height: 350px;
    width: 100%;
    content: '';
  }
}

.hero--event {
  min-height: 450px;
}

.post-preview--featured {
  color: $white;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px !important;
}

.post-preview__title--featured, .post-preview__title--internal {
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 1.6rem;
  margin-top: 0.4rem;
  color: $white;

  &:hover {
    color: $white;
  }
}

.post-preview__title--internal {
  &:hover {
    text-decoration: none;
  }
}

.post-preview--sub-featured {
  border-bottom: $border;
  margin-top: 12px;
  padding-bottom: 10px;
  margin-left: 1.2rem;
}

.sub-featured {
  position: relative;
}

.sub-featured__number {
  position: absolute;
  left: 0;
  color: $blue;
  font-size: 1rem;
  font-weight: 500;
}

.sub-featured__heading-param {
  color: $blue;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 800;
  font-family: 'Montserrat', sans-serif;

  @include breakpoint(medium down) {
    margin-top: 1rem;
  }
}

.post-preview__category-wrapper {
  display: inline-block;
}

@include breakpoint(small only) {
  .post-preview__category-wrapper {
    display:block;
  }
}

.post-preview__img-wrapper {
  padding: 2rem 0 0.7rem 0;
}

.post-preview__img {
  width: 100%;
}

.button {
  width: 100%;
  margin: 3rem auto !important;
  background-color: transparent !important;
  border: $border !important;
  border-radius: 2px !important;
}

.button__icon {
  color: $pink;
}

.button__text {
  color: $blue;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
}

.pink {
  color: $pink;
}
