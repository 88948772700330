.contact-map-wrapper {
  position: relative;
  margin-top: -2rem;

  @include breakpoint(small only) {
    margin-top: -0.7rem;
  }
}

.contact-map-large {
  width: 100%;
}

.contact-map-small {
  width: 100%;
}

.contact-box {
  display: inline-block;
  padding: 1rem;
  background: $white;
  position: absolute;
  left: 26%;
  top: 50%;
  transform: translateY(-50%);

  @include breakpoint(medium only) {
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @include breakpoint(small only) {
    left: 2%;
  }
}

.contact-box__header {
  color: $blue;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0;
}

.contact-box__body {
  font-size: 0.8rem;
}

.contact-box__link {
  text-decoration: none;
  color: $dark-gray;
  padding-left: 0.25rem;
}

.contact-main {
  text-align: center;
  margin-top: 3rem;
}

.contact-main__paragraph {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

.contact-main__link {
  color: $pink;
  text-decoration: underline;
}

.main-header--contact {
  margin-bottom: 0;
}

.contact-form__label {
  position: absolute;
  font-size: 0.7rem;
  padding-left: 5px;
}

.contact-form__input {
  margin-bottom: 0 !important;
  height: 54px !important;
  color: $blue !important;
  width: 100%;
}

.contact-form__input-body {
  padding-top: 18px;
  height: 200px;
  margin-bottom: 0;
}

.contact-form__input--image {
  padding-top: 16px;

  @include breakpoint(large) {
    padding-left: 16px;
  }
}

.button--contact-form {
  margin-top: 2rem !important;
}

.contact-form__button-text {
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
}

//Custom upload button
.contact-form__upload-button {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  margin: 1rem auto;
  margin-bottom: .25rem;
  background-color: transparent;
  border: 1px solid #e8e6ee;
  border-radius: 2px;
  padding: 0.85em 2em;
  font-weight: 700;
}

.label__text {
  margin-bottom: .5rem;
}
