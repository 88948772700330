.event-preview {
  margin-bottom: 2rem;
}

.event-preview__content {
  height: 100%;
  border-bottom: $border;
  padding-bottom: 1rem;
}

.button--event {
  margin-top: 0.5rem !important;
  margin-bottom: 0rem !important;
}

.button__text--event {
  color: $white;
}

// .main-header--month {
//   font-size: 1.4rem;
//   font-family: "Roboto Condensed", sans-serif;
// }

.sub-header--events {
  font-size: 1rem;
}

.post-preview__social {
  a {
    .post-preview__social__icon {
      color: #7d7d9d;
    }
  }
}